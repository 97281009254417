import {
  encodeAbiParameters,
  encodeFunctionData,
  erc20Abi,
  PublicClient,
  WalletClient,
} from "viem";
import memoize from "memoizee";
import { formatStringAsAddress } from "./formatAddress";

// use the wallet client to get the balance of an ERC-20 token
export const getErc20Balance = async (
  publicClient: PublicClient,
  accountToGetBalanceOf: string,
  contractAddress: string,
  decimals: number = 18
): Promise<bigint> => {
  const balanceOfEncoded = encodeFunctionData({
    abi: erc20Abi,
    functionName: "balanceOf",
    args: [formatStringAsAddress(accountToGetBalanceOf)], // The wallet's own address
  });
  if (!publicClient) {
    throw new Error("Public client not initialized");
  }

  const balance = await publicClient
    .request({
      method: "eth_call",
      params: [
        {
          to: `0x${contractAddress.replace(/^0x/, "")}`,
          data: balanceOfEncoded,
        },
        "latest",
      ],
    })
    .then((result) => {
      if (result === "0x") {
        return BigInt(0);
      }
      return BigInt(result);
    });
  console.log(
    "Getting balance for address: ",
    accountToGetBalanceOf,
    contractAddress,
    balance,
    publicClient.chain?.rpcUrls
  );
  return balance;
};

export const getAssetBalance = async (
  address: string,
  contractAddress: string,
  walletClient: WalletClient,
  publicClient: PublicClient,
  tradeAssetDecimals: number
): Promise<bigint> => {
  const memoizedFn = memoize(
    async (
      accountAddress: string,
      contractAddress: string
    ): Promise<bigint> => {
      if (!walletClient?.request) {
        throw new Error("No client found");
      }
      const baseAssetBalance = await getErc20Balance(
        publicClient,
        accountAddress,
        contractAddress,
        tradeAssetDecimals
      );
      return baseAssetBalance;
    },
    { maxAge: 5000, promise: true }
  );
  if (!walletClient?.account?.address) {
    throw new Error("No account address found");
  }
  return await memoizedFn(address, contractAddress);
};