import { Outlet, useLocation, useNavigate } from "react-router-dom";

import "./App.css";

import {
  useNotification,
  useConnection,
  useSmartObject,
} from "@eveworld/contexts";
import {
  EveConnectWallet,
  EveFeralCodeGen,
  ErrorNotice,
  ErrorNoticeTypes,
  EveLayout,
  EveAlert,
  EveButton,
} from "@eveworld/ui-components";
import { useQueryParamRedirect } from "./hooks";
import { createNavLocation } from "./utils/nav";
import { isOwner } from "@eveworld/utils/utils";
import { useEffect, useState } from "react";
import { setupMud } from "./mud";
import { MUDProvider } from "./MUDContext";

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    connectedProvider,
    publicClient,
    walletClient,
    isCurrentChain,
    handleConnect,
    handleDisconnect,
    availableWallets,
    defaultNetwork,
  } = useConnection();
  const { notification } = useNotification();
  const { loading, smartAssembly, smartCharacter } = useSmartObject();
  const [mudSetupResult, setMudSetupResult] = useState<any | null>(null);

  const { connected } = connectedProvider;
  useEffect(() => {
    // set mud up if it hasn't been set up yet
    if (mudSetupResult || !walletClient) return;
    setupMud(walletClient).then(setMudSetupResult).catch(console.error);
  }, [walletClient]);
  const isEntityOwner: boolean = isOwner(
    smartAssembly,
    walletClient?.account?.address
  );
  if (!connected || !publicClient || !walletClient) {
    return (
      <div className="h-full w-full bg-crude-5 -z-10">
        <EveConnectWallet
          handleConnect={handleConnect}
          availableWallets={availableWallets}
        />
        <GenerateEveFeralCodeGen style="top-12" />
        <GenerateEveFeralCodeGen style="bottom-12" />
      </div>
    );
  }
  const isAdminPanelOpen = location.search.includes("showAdminPanel");
  const isMapOpen = location.pathname.includes("map");
  return (
    <>
      <MUDProvider value={mudSetupResult}>
        <EveAlert
          defaultNetwork={defaultNetwork}
          message={notification.message}
          txHash={notification.txHash}
          severity={notification.severity}
          handleClose={notification.handleClose}
          isOpen={notification.isOpen}
          isStyled={false}
        />

        <EveLayout
          isCurrentChain={isCurrentChain}
          connected={connectedProvider.connected}
          handleDisconnect={handleDisconnect}
          walletClient={walletClient}
          smartCharacter={smartCharacter}
        >
          <div
            className="flex flex-col align-center border items-stretch border-brightquantum"
            id="smartassembly-name"
          >
            <div
              className="flex flex-row border items-stretch border-brightquantum"
              id="smartassembly-name"
            >
              <div
                className="Quantum-Container font-semibold grid grid-cols-4 gap-2 flex flex-row align-center "
                style={{ flex: 1, justifyContent: "center" }}
                id="SmartObject-Actions"
              >
                <EveButton
                  typeClass="primary"
                  onClick={() => handleDisconnect()}
                  className="primary-sm"
                  id="bring-online-offline"
                >
                  <span>Disconnect</span>
                </EveButton>
                <EveButton
                  typeClass="primary"
                  onClick={() => {
                    const searchParams = new URLSearchParams(location.search);
                    searchParams.delete("smartObjectId");
                    navigate(
                      `/${Object.entries(searchParams).reduce(
                        (acc, [value, key], idx) => {
                          acc +=
                            idx === 0 ? `${key}=${value}` : `&${key}=${value}`;
                          return acc;
                        },
                        ""
                      )}`
                    );
                  }}
                  disabled={(() => {
                    const searchParams = new URLSearchParams(location.search);
                    // Check if 'smartObjectId' query parameter exists
                    const smartObjectId = searchParams.get("smartObjectId");
                    return !smartObjectId;
                  })()}
                  className="primary-sm"
                  id="bring-online-offline"
                >
                  <span>My Structures</span>
                </EveButton>
                <EveButton
                  typeClass="primary"
                  onClick={() => {
                    const searchParams = new URLSearchParams(location.search);
                    isAdminPanelOpen
                      ? searchParams.delete("showAdminPanel")
                      : searchParams.set("showAdminPanel", "true");
                    const navLoc = createNavLocation(searchParams);
                    console.debug("Navigating to", navLoc);
                    navigate(navLoc);
                  }}
                  className="primary-sm"
                  disabled={(() => {
                    const searchParams = new URLSearchParams(location.search);
                    // Check if 'smartObjectId' query parameter exists
                    const smartObjectId = searchParams.get("smartObjectId");
                    return !isCurrentChain || !isEntityOwner || !smartObjectId;
                  })()}
                  id="edit-unit"
                >
                  {`To ${isAdminPanelOpen ? "Player" : "Admin"} Panel`}
                </EveButton>
                <EveButton
                  typeClass="primary"
                  onClick={() => {
                    const searchParams = new URLSearchParams(location.search);
                    const navLoc = isMapOpen
                      ? createNavLocation(searchParams, "/")
                      : createNavLocation(searchParams, "/map");
                    console.debug("Navigating to", navLoc);
                    navigate(navLoc);
                  }}
                  className="primary-sm"
                  id="edit-unit"
                >
                  {isMapOpen ? `Exit Map` : `To Map`}
                </EveButton>
              </div>
            </div>
            {isCurrentChain ? (
              <Outlet />
            ) : (
              <ErrorNotice
                loading={loading}
                smartAssembly={smartAssembly}
                type={ErrorNoticeTypes.MESSAGE}
                errorMessage={`Switch network to ${walletClient.chain?.name} to continue`}
              />
            )}
          </div>
          {/* {isDev && <EveConsole />} */}
        </EveLayout>
        <GenerateEveFeralCodeGen style="bottom-12 text-xs -z-10" />
      </MUDProvider>
    </>
  );
};

export default App;

const GenerateEveFeralCodeGen = ({
  style,
  count = 5,
}: {
  style?: string;
  count?: number;
}) => {
  const codes = Array.from({ length: count }, (_, i) => i);
  return (
    <div
      className={`absolute flex justify-between px-10 justify-items-center w-full text-xs ${style}`}
    >
      {codes.map((index) => (
        <EveFeralCodeGen key={index} />
      ))}{" "}
    </div>
  );
};