import React, { ReactNode } from "react";
import { TextField } from "@mui/material";
import * as NumberField from "@base_ui/react/NumberField";

const parseShorthand = (value: string | number) => {
  if (typeof value === "string") {
    const suffix = value.slice(-1).toLowerCase();
    const number = parseFloat(value.slice(0, -1));
    switch (suffix) {
      case "k":
        return number * 1_000;
      case "m":
        return number * 1_000_000;
      case "b":
        return number * 1_000_000_000;
      default:
        return parseFloat(value);
    }
  }
  return value;
};
/**
 * Component for editing text fields with different input types.
 *
 * @param inputType - The type of input: "string", "multiline", or "numerical".
 * @param defaultValue - The default value for the text field.
 * @param fieldName - The name of the field being input.
 * @param onChange - Function called when the value changes.
 * @param placeholder - Optional placeholder text for the field.
 *
 * @returns JSX Element representing the text editing component.
 */
export default function Input(props: {
  inputType: "string" | "multiline" | "numerical";
  defaultValue: React.MutableRefObject<string | number> | ReactNode;
  fieldName: string;
  fieldVal: string | number | undefined;
  onChange: (value: string | number | undefined) => void;
  placeholder?: string;
}): JSX.Element {
  const { inputType, fieldName, onChange, fieldVal, placeholder } = props;
  const defaultIsString = () => {
    if (typeof props.defaultValue === "string") return props.defaultValue;
  };

  const handleChange = (event: any) => {
    const { value } = event.target;
    if (value === "") {
      onChange(0);
      return;
    }
    if (Number.isNaN(parseFloat(parseShorthand(value).toString()))) {
      console.error("Invalid input");
      return;
    }

    onChange(parseShorthand(value));
  };

  const fieldId = fieldName.replace(/\s/g, "");

  return (
    <div className="TextField grid my-1 w-full" id={`edit-${fieldId}`}>
      <span className="text-xs font-bol uppercase">{fieldName}</span>
      {inputType == "numerical" ? (
        <NumberField.Root
          id={`input-${fieldId}`}
          // style={{ padding: "0px !important" }}
          className={"border-brightquantum border"}
          aria-label={`numerical-input-${fieldId}`}
          min={0}
          value={
            typeof fieldVal === "string" ? parseShorthand(fieldVal) : fieldVal
          }
          format={{ maximumFractionDigits: 6 }}
          onValueChange={(val: any) => onChange(parseShorthand(val))}
        >
          <NumberField.Group className=" flex flex-row">
            <NumberField.Decrement className="text-sm leading-4 uppercase font-bold mobile:px-0 px-2 py-1 bg-brightquantum text-crude">
              &minus;
            </NumberField.Decrement>
            <NumberField.Input
              className="text-center py-1 bg-crude text-brightquantum"
              placeholder={defaultIsString() ?? placeholder ?? fieldName}
            />
            <NumberField.Increment className="text-sm leading-4 uppercase font-bold mobile:px-0 px-2 py-1 bg-brightquantum text-crude">
              +
            </NumberField.Increment>
          </NumberField.Group>
        </NumberField.Root>
      ) : (
        <TextField
          multiline={inputType == "multiline"}
          minRows={inputType == "multiline" ? 5 : undefined}
          id={`input-${fieldId}`}
          placeholder={defaultIsString() ?? placeholder ?? fieldName}
          defaultValue={defaultIsString()}
          className="flex-grow"
          onChange={(e) => handleChange(e.target.value)}
          autoComplete="off"
          autoFocus
        />
      )}
    </div>
  );
}
