import { CoordsWithMeta } from "./types";

export const scaleCoordinatesToRange = (
  coords: CoordsWithMeta[],
  scaleTo: number = 250
): CoordsWithMeta[] => {
  const maxX = Math.max(...coords.map((c) => Math.abs(c.x)));
  const maxY = Math.max(...coords.map((c) => Math.abs(c.y)));
  const maxZ = Math.max(...coords.map((c) => Math.abs(c.z)));
  const max = Math.max(maxX, maxY, maxZ); // Find the maximum value to scale proportionally

  const scaleFactorZ = scaleTo / maxZ;
  const scaleFactor = scaleTo / max;

  return coords.map((c) => ({
    ...c,
    x: c.x * scaleFactor,
    y: c.y * scaleFactor,
    z: c.z * scaleFactorZ,
  }));
};

export const noOp = () => {};

export const shiftCoordinatesToCenter = (
  coords: CoordsWithMeta[],
  middle: { x: number; y: number; z: number }
): CoordsWithMeta[] => {
  return coords.map((c) => ({
    ...c,
    x: c.x - middle.x,
    y: c.y - middle.y,
    z: c.z - middle.z,
  }));
};

export const extractMiddleOfCoords = (coords: CoordsWithMeta[]) => {
  const allX = coords.reduce((acc, curr) => acc + curr.x, 0);
  const allY = coords.reduce((acc, curr) => acc + curr.y, 0);
  const allZ = coords.reduce((acc, curr) => acc + curr.z, 0);

  const len = coords.length;

  return {
    x: allX / len,
    y: allY / len,
    z: allZ / len,
  };
};
