import { useState } from "react";
import GroupDisplay from "./Group";
import { IFullMarketState } from "./types";
import { EveButton } from "@eveworld/ui-components";
import { useLocation, useNavigate } from "react-router-dom";

const CategoryDisplay = ({
  fullMarketState,
  itemGroupingToDisplay,
}: {
  fullMarketState: IFullMarketState;
  itemGroupingToDisplay: Map<string, Map<string, bigint[]>>;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showCategory, setShowCategory] = useState(true);
  const toggleCategory = () => setShowCategory(!showCategory);
  return (
    <>
      {Array.from(itemGroupingToDisplay.entries()).map(
        ([categoryId, groupingMap]) => (
          <div
            key={`category-id-${categoryId}`}
            className="flex flex-col w-full"
          >
            <EveButton
              typeClass="secondary"
              className="Title bg-quantum flex flex-row justify-between w-full p-2"
              onClick={toggleCategory}
            >
              <div className="flex flex-row justify-between w-full">
                <span className="font-bold">{categoryId}</span>
                <span className="font-bold text-md">
                  {showCategory ? "-" : "+"}
                </span>
              </div>
            </EveButton>
            {showCategory && (
              <GroupDisplay
                groupMap={groupingMap}
                fullMarketState={fullMarketState}
                selectItem={(itemId) => {
                  // get current query params
                  const params = new URLSearchParams(location.search);
                  // add itemId to it
                  params.set("itemId", itemId.toString());
                  // push new url
                  navigate(`${location.pathname}?${params.toString()}`);
                }}
              />
            )}
          </div>
        )
      )}
    </>
  );
};

export default CategoryDisplay;
