import { useEffect, useState } from "react";
import { SmartAssemblyType } from "@eveworld/types/types";
import { WalletClient } from "viem";
import { deriveOrderType, deriveOrderTypeDisplay } from "@/utils/displayFormat";
import { OrderType, SavedOrder } from "@/types/orders";
import BaseOrder from "../../Order";
import { useMUD } from "@/MUDContext";
import { useEffectOnce } from "@/utils/hooks/useEffectOnce";
import { sortBigIntAscending, sortBigIntDescending } from "../../utils";
import FixedLengthTable from "@/components/FixedLengthTable";
import { useSmartObject } from "@eveworld/contexts";

interface IState {
  openPlayerOrders: SavedOrder[];
  closedPlayerOrders: SavedOrder[];
}

const PlayerOrders = ({
  characterId,
  tradeAssetBalance,
  tradeAssetDecimals,
  tradeAssetTicker,
  showTradeAssetBalance = false,
}: {
  characterId: string;
  tradeAssetBalance: bigint;
  tradeAssetDecimals: number;
  tradeAssetTicker: string;
  showTradeAssetBalance?: boolean;
}) => {
  const { smartAssembly } = useSmartObject();
  const [state, setState] = useState<IState>({
    openPlayerOrders: [],
    closedPlayerOrders: [],
  });
  const {
    systemCalls: { getOpenPlayerOrders, getClosedPlayerOrders },
  } = useMUD();
  useEffectOnce(() => {
    Promise.all([
      getOpenPlayerOrders(BigInt(characterId)),
      getClosedPlayerOrders(BigInt(characterId)),
    ]).then(([openOrders, closedOrders]) => {
      // console.log("closedOrders", closedOrders);
      // console.log("openOrders", openOrders);

      setState((prevState) => ({
        ...prevState,
        closedPlayerOrders: closedOrders,
        openPlayerOrders: openOrders,
      }));
    });
  });
  const buyOrders = state.openPlayerOrders
    .filter((order) => deriveOrderType(order.orderType) === OrderType.LIMIT_BUY)
    .sort((a, b) => sortBigIntDescending(a.createdTime, b.createdTime));
  const sellOrders = state.openPlayerOrders
    .filter(
      (order) => deriveOrderType(order.orderType) === OrderType.LIMIT_SELL
    )
    .sort((a, b) => sortBigIntDescending(a.createdTime, b.createdTime));
  const closedOrders = state.closedPlayerOrders.sort((a, b) =>
    sortBigIntDescending(a.finishedTime, b.finishedTime)
  );
  return (
    <>
      {buyOrders && buyOrders.length > 0 ? (
        <FixedLengthTable
          title="Open Buy Orders"
          headers={{
            quantity: { title: "Quantity Remaining" },
            price: { title: "Price", style: { maxWidth: "100px" } },
            location: { title: "Location" },
            jumps: { title: "Jumps", style: { maxWidth: "50px" } },
            createdTime: {
              title: "Created Time",
              style: { maxWidth: "80px" },
            },
          }}
          tradeAssetTicker={tradeAssetTicker}
          arrayOfRecords={buyOrders.map((order) => {
            return {
              price: order.price,
              quantity: `${order.quantityRemaining.toString()}/${order.startingQuantity.toString()}`,
              location: smartAssembly?.solarSystem.solarSystemName
                ? `${smartAssembly?.solarSystem.solarSystemName} - ${smartAssembly.id.slice(0, 5)} `
                : "Unknown",
              jumps: "0",
              createdTime: new Date(
                parseInt(order.createdTime.toString()) * 1000
              ),
            };
          })}
        />
      ) : (
        <>
          <div className="Quantum-Container Text">Buy Orders</div>
          <div className="Quantum-Container text-xs">No open buy orders</div>
        </>
      )}
      {sellOrders && sellOrders.length > 0 ? (
        <FixedLengthTable
          title="Open Sell Orders"
          headers={{
            quantity: { title: "Quantity Remaining" },
            price: { title: "Price", style: { maxWidth: "100px" } },
            location: { title: "Location" },
            jumps: { title: "Jumps", style: { maxWidth: "50px" } },
            createdTime: {
              title: "Created Time",
              style: { maxWidth: "80px" },
            },
          }}
          tradeAssetTicker={tradeAssetTicker}
          arrayOfRecords={sellOrders.map((order) => {
            return {
              price: order.price,
              quantity: `${order.quantityRemaining.toString()}/${order.startingQuantity.toString()}`,
              location: smartAssembly?.solarSystem.solarSystemName
                ? `${smartAssembly?.solarSystem.solarSystemName} - ${smartAssembly.id.slice(0, 5)} `
                : "Unknown",
              jumps: "0",
              createdTime: new Date(
                parseInt(order.createdTime.toString()) * 1000
              ),
            };
          })}
        />
      ) : (
        <>
          <div className="Quantum-Container Text">Sell Orders</div>
          <div className="Quantum-Container text-xs">No open sell orders</div>
        </>
      )}
      {closedOrders && closedOrders.length > 0 ? (
        <FixedLengthTable
          title="Closed Orders"
          headers={{
            quantity: { title: "Quantity Filled" },
            price: { title: "Price" },
            orderType: { title: "Order Type" },
            location: { title: "Location" },
            completionTime: {
              title: "Completed Time",
              style: { maxWidth: "80px" },
            },
          }}
          tradeAssetTicker={tradeAssetTicker}
          arrayOfRecords={closedOrders.map((order) => {
            return {
              price: order.price,
              quantity: `${(order.startingQuantity - order.quantityRemaining).toString()}/${order.startingQuantity.toString()}`,
              orderType: deriveOrderTypeDisplay(order.orderType),
              location: smartAssembly?.solarSystem.solarSystemName
                ? `${smartAssembly?.solarSystem.solarSystemName} - ${smartAssembly.id.slice(0, 5)} `
                : "Unknown",
              completionTime: order.finishedTime
                ? new Date(parseInt(order.finishedTime.toString()) * 1000)
                : new Date(0),
            };
          })}
        />
      ) : (
        <>
          <div className="Quantum-Container Text">Closed Orders</div>
          <div className="Quantum-Container text-xs">No closed orders</div>
        </>
      )}
    </>
  );
};
export default PlayerOrders;
