import { useState, useEffect, useRef } from "react";
import { default as ForceGraph3D, ForceGraph3DInstance } from "3d-force-graph";
import SpriteText from "three-spritetext";
import { EveButton, EveInput } from "@eveworld/ui-components";

import smartGateLocations from "../../assets/smart_gate_locs.json";
import jumpDriveLocations from "../../assets/jump_drive_locs.json";
import { useEffectOnce } from "../../utils/hooks";
import "./canvas.css";

import { CoordsWithMeta, SimpleCoordsWithMeta } from "./types";
import {
  extractMiddleOfCoords,
  scaleCoordinatesToRange,
  shiftCoordinatesToCenter,
  noOp,
} from "./utils";
import { set } from "cypress/types/lodash";

const GalaxyMap = () => {
  const graphRef = useRef(null);
  const graphInstanceRef = useRef<null | ForceGraph3DInstance>(null);
  const [error, setError] = useState<string | null>(null);
  const [focusedNode, setFocusedNode] = useState<
    CoordsWithMeta | object | null
  >(null);
  const [mapCoords, setMapCoords] = useState<{
    locations: SimpleCoordsWithMeta[];
    type: "Smart Gate" | "Jump Drive";
  }>({ locations: smartGateLocations, type: "Smart Gate" });
  const locationCoordsToUse = mapCoords.locations
    .filter((item) => {
      return !!(
        item.solarSystemId &&
        item.a_name &&
        item.locationX &&
        item.locationY &&
        item.locationZ
      );
    })
    .map((item) => {
      return {
        ...item,
        x: item.locationX,
        y: item.locationY,
        z: item.locationZ,
      };
    });
  //   const locationCoordsScaled = scaleEachTo100(locationCoordsToUse);
  // filter out locations that don't have all the required fields
  const middlePosition = extractMiddleOfCoords(locationCoordsToUse);
  const shiftedCoords = shiftCoordinatesToCenter(
    locationCoordsToUse,
    middlePosition
  );
  const scaledCoords = scaleCoordinatesToRange(shiftedCoords);
  const scaledMiddlePosition = extractMiddleOfCoords(scaledCoords);

  const deriveCameraPosition = () => {
    if (focusedNode) {
      const distance = 100;
      const distRatio =
        1 + distance / Math.hypot(focusedNode.x, focusedNode.y, focusedNode.z);
      return {
        x: focusedNode.x * distRatio,
        y: focusedNode.y * distRatio,
        z: focusedNode.z * distRatio,
      };
    }
    return { ...scaledMiddlePosition, y: 250, z: 250 };
  };

  useEffect(() => {
    const graph = ForceGraph3D()(graphRef.current as any); // Initialize the 3D force graph on the ref
    graphInstanceRef.current = graph;
    const nodes = scaledCoords.map((location, idx) => {
      return {
        id: idx,
        solarSystemId: location.solarSystemId,
        name: location.a_name,
        fx: location.x,
        fy: location.y,
        fz: location.z,
        relations: location.relations,
        relationsLength: location.relations.length,
      };
    });
    const linksMap = new Map<
      string,
      { source: number; name: string; target: number }
    >();
    const mapOfNodes = nodes.reduce<Record<string, any>>((acc, curr) => {
      acc[curr.solarSystemId.toString()] = curr;
      return acc;
    }, {});
    nodes.forEach((node) => {
      node.relations.forEach((rel) => {
        const targetNode = mapOfNodes[rel.solarSystemId.toString()];
        if (!targetNode) {
          return;
        }
        const linkId = `${node.solarSystemId.toString()}-${targetNode.solarSystemId.toString()}`;
        const duplicateLinkId = `${targetNode.solarSystemId.toString()}-${node.solarSystemId.toString()}`;
        if (
          linksMap.has(linkId) ||
          linksMap.has(duplicateLinkId) ||
          node.solarSystemId === targetNode.solarSystemId
        ) {
          return;
        }
        linksMap.set(linkId, {
          source: node.id,
          target: targetNode.id,
          name: `${node.name} - ${targetNode.name}: ${rel.distance.slice(0, 6)}LY`,
        });
        return;
      });
    });
    const data = {
      nodes,
      links: Array.from(linksMap.values()),
    };

    // Attach the graph data
    graph
      .graphData(data as any)
      .width(540)
      .height(500)
      .nodeAutoColorBy("relationsLength") // Color nodes by their ID
      .nodeRelSize(3)
      .nodeLabel("")
      .linkLabel("")
      .nodeResolution(32)
      .linkWidth(1.2)
      .linkLabel("name")
      .cameraPosition(deriveCameraPosition()) // Adjust camera position
      //   .linkThreeObject((link: any) => {
      //     const sprite = new SpriteText(link.name);
      //     sprite.color = link.color;
      //     sprite.textHeight = 5;
      //     // sprite.position.y = -5;
      //     sprite.fontFace = "Arial";

      //     // Adjust sprite position and scale as needed
      //     // sprite.scale.set(20, 10, 1); // Adjust size
      //     return sprite;
      //   })
      //   .linkThreeObjectExtend(true) //   .cameraPosition({ ...scaledMiddlePosition, z: 150 }) // Adjust camera position
      .nodeThreeObject((node: any) => {
        const sprite = new SpriteText(node.name);
        sprite.color = node.color;
        sprite.textHeight = 1;
        sprite.position.y = -5;
        sprite.position.x = -2;

        sprite.fontFace = `"Sometype Mono", sans-serif`;
        sprite.fontWeight = "bold";
        // Adjust sprite position and scale as needed
        sprite.scale.set(30, 6, 1); // Adjust size
        return sprite;
      })
      .nodeThreeObjectExtend(true) //   .cameraPosition({ ...scaledMiddlePosition, z: 150 }) // Adjust camera position
      .enableNodeDrag(false)
      .onNodeClick((node) => setFocusedNode(node)); // Show particles on links
    // Clean up on unmount
    return () => {
      graph.pauseAnimation();
      if (graphRef.current) {
        (graphRef.current as any).innerHTML = ""; // Clean up the DOM element
      }
    };
  }, [mapCoords]);
  useEffect(() => {
    if (!focusedNode) return;
    if (
      !graphRef ||
      graphInstanceRef === null ||
      !graphInstanceRef.current ||
      graphInstanceRef.current === null
    ) {
      console.error("No graph ref found");
    }
    (graphInstanceRef.current as any).cameraPosition(
      deriveCameraPosition(), // new position
      focusedNode, // lookAt ({ x, y, z })
      1500 // ms transition duration
    );
  }, [focusedNode]);
  return (
    <div className="bg-crude">
      <div className="Quantum-Container Title">{`${mapCoords.type} Map`}</div>
      <div className="Quantum-Container">
        <span className="text-xs">{`This Map contains all of the systems with deployed smart structures and possible
        ${mapCoords.type} links (<${mapCoords.type === "Smart Gate" ? "8" : "5"} Light Years distant). To search for a system, just enter the name of the system below and the map will focus on it.`}</span>
        <EveButton
          typeClass="secondary"
          onClick={() => {
            setMapCoords({
              locations:
                mapCoords.type === "Smart Gate"
                  ? jumpDriveLocations
                  : smartGateLocations,
              type:
                mapCoords.type === "Smart Gate" ? "Jump Drive" : "Smart Gate",
            });
          }}
        >
          {`Show ${mapCoords.type === "Smart Gate" ? "Jump Drive" : "Smart Gate"} Map`}
        </EveButton>
        <EveInput
          inputType="string"
          fieldName=""
          defaultValue=""
          placeholder="System name - (e.g. Cydias)"
          onChange={(name) => {
            if (!name) {
              setError(null);
              return;
            }
            const node = scaledCoords.find((item) => {
              return (
                item.a_name.toLowerCase() === (name as string).toLowerCase()
              );
            });
            if (!node) {
              setError(`No node found for system name: ${name}`);
              return;
            }
            setError(null);
            setFocusedNode(node);
          }}
        />
        {error !== null && <div>Error searching for system. {error}</div>}
      </div>
      <div className="Quantum-Container">
        {graphRef ? (
          <div
            ref={graphRef as any}
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              position: "relative",
            }}
          />
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </div>
  );
};

export default GalaxyMap;
