import { OrderType } from "@/types/orders";
import { DEFAULT_TOKEN_DECIMALS } from "@/constants";
import { getAdjustedUnitPrice } from "./utils";

export interface ITradeWindowState {
  price: {
    value: bigint | null;
    feeBasisPoints: bigint;
    feeAdjustedPrice: bigint | null;
    error: string | null;
  };
  quantity: {
    value: bigint | null;
    error: string | null;
  };
  tradeAssetTicker: string;
  tradeAssetDecimals: number;
  isFetchingPrice: boolean;
  orderType: OrderType;
  itemId: bigint | null;
  isSubmittingOrder?: boolean;
  pendingTxs: string[];
}

// Used to represent stacked orders by locale and price based on original SavedOrder
export const baseState: ITradeWindowState = {
  price: {
    value: 0n,
    feeAdjustedPrice: 0n,
    feeBasisPoints: 0n,
    error: null,
  },
  quantity: {
    value: 0n,
    error: null,
  },
  isFetchingPrice: true,
  tradeAssetTicker: "",
  tradeAssetDecimals: DEFAULT_TOKEN_DECIMALS,
  orderType: OrderType.MARKET_BUY,
  itemId: null,
  isSubmittingOrder: false,
  pendingTxs: [],
};

export const createBaseState = (
  itemId: string | null,
  tradeAssetTicker: string
): ITradeWindowState => {
  return {
    ...baseState,
    tradeAssetTicker,
    itemId: itemId ? BigInt(itemId) : null,
  };
};

export type IActions =
  | { type: "SET_ITEM"; payload: bigint }
  | { type: "SET_PRICE"; payload: { value: bigint; error?: string | null } }
  | { type: "SET_QUANTITY"; payload: { value: bigint; error?: string | null } }
  | {
      type: "SET_ORDER_TYPE";
      payload: { orderType: OrderType };
    }
  | {
      type: "SET_PRICE_ERROR";
      payload: string | null;
    }
  | {
      type: "SET_QUANTITY_ERROR";
      payload: string;
    }
  | {
      type: "CLEAR_ERRORS";
    }
  | { type: "SET_SUBMITTING_ORDER" }
  | { type: "MARK_ORDER_SUBMITTED" }
  | { type: "MARK_ORDER_REJECTED" }
  | {
      type: "SET_FEE_ADJUSTED_PRICE";
      payload: { feeBasisPoints: bigint; feeAdjustedPrice: bigint };
    }
  | { type: "ADD_PENDING_TX"; payload: string } // tx hash
  | { type: "REMOVE_PENDING_TX"; payload: string }; // tx hash

export const tradeWindowReducer = (
  state: ITradeWindowState,
  action: IActions
): ITradeWindowState => {
  // console.warn("[tradeWindowReducer]", { action });
  switch (action.type) {
    case "SET_ITEM":
      return {
        ...state,
        itemId: action.payload,
      };
    case "SET_PRICE": {
      const feeAdjustedPrice =
        state.orderType === OrderType.LIMIT_BUY ||
        state.orderType === OrderType.MARKET_BUY
          ? getAdjustedUnitPrice(
              action.payload.value,
              state.tradeAssetDecimals,
              state.price.feeBasisPoints
            )
          : state.price.value;
      console.log(
        "SET_PRICE",
        feeAdjustedPrice,
        action.payload.value,
        state.price.feeBasisPoints
      );
      return {
        ...state,
        price: {
          ...state.price,
          error: action.payload.error || state.price.error,
          value: BigInt(action.payload.value),
          feeAdjustedPrice: feeAdjustedPrice,
        },
      };
    }
    case "SET_QUANTITY":
      return {
        ...state,
        quantity: {
          ...state.quantity,
          value: BigInt(action.payload.value),
          error: action.payload.error || state.quantity.error,
        },
      };
    case "SET_ORDER_TYPE":
      return {
        ...state,
        orderType: action.payload.orderType,
      };
    case "SET_PRICE_ERROR": {
      return {
        ...state,
        price: {
          ...state.price,
          error: action.payload,
        },
      };
    }
    case "SET_QUANTITY_ERROR": {
      return {
        ...state,
        quantity: {
          ...state.quantity,
          error: action.payload,
        },
      };
    }
    case "CLEAR_ERRORS": {
      return {
        ...state,
        price: {
          ...state.price,
          error: null,
        },
        quantity: {
          ...state.quantity,
          error: null,
        },
      };
    }
    case "SET_SUBMITTING_ORDER":
      return {
        ...state,
        isSubmittingOrder: true,
      };
    case "MARK_ORDER_SUBMITTED":
      return {
        ...state,
        isSubmittingOrder: false,
      };
    case "MARK_ORDER_REJECTED":
      return {
        ...state,
        isSubmittingOrder: false,
      };
    case "SET_FEE_ADJUSTED_PRICE": {
      console.log(
        "SET_FEE_ADJUSTED_PRICE",
        action.payload.feeAdjustedPrice,
        action.payload.feeBasisPoints
      );
      return {
        ...state,
        price: {
          ...state.price,
          feeBasisPoints: action.payload.feeBasisPoints,
          feeAdjustedPrice: action.payload.feeAdjustedPrice,
        },
      };
    }
    case "ADD_PENDING_TX":
      return {
        ...state,
        pendingTxs: [...state.pendingTxs, action.payload],
      };
    case "REMOVE_PENDING_TX":
      return {
        ...state,
        pendingTxs: state.pendingTxs.filter((tx) => tx !== action.payload),
      };
    default:
      return state;
  }
};
