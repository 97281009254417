import { useEffect, useState } from "react";
import { SmartAssemblyType } from "@eveworld/types/types";
import { WalletClient } from "viem";

import { useMUD } from "../../MUDContext";
import BaseOrder from "./Order";
import { deriveOrderType, useEffectOnce } from "../../utils";
import { OrderType } from "../../types/orders";
import { deriveViewFromPath, deriveViewFromSelectedTab } from "./utils";
import { DexView } from "./types";
import DexNav from "./DexNav";
import { useLocation, useNavigate } from "react-router-dom";
import { useSmartObject } from "@eveworld/contexts";

interface IState {
  view: DexView;
}
const Dex = ({
  characterId,
  smartAssembly,
  walletClient,
  tradeAssetBalance,
  tradeAssetDecimals,
  tradeAssetTicker,
  showTradeAssetBalance = false,
}: {
  characterId: string;
  smartAssembly: SmartAssemblyType<"SmartStorageUnit">;
  walletClient: WalletClient;
  tradeAssetBalance: bigint;
  tradeAssetDecimals: number;
  tradeAssetTicker: string;
  showTradeAssetBalance?: boolean;
}) => {
  const [state, setState] = useState<IState>({
    view: DexView.YOUR_ORDERS,
  });
  const [shouldShowDescription, setShouldShowDescription] = useState(true);
  const toggleDescription = () => {
    setShouldShowDescription((prevState) => !prevState);
  };
  const location = useLocation();
  const navigate = useNavigate();
  const changeView = (view: DexView) => {
    setState((prevState) => ({
      ...prevState,
      view,
    }));
  };
  // change the view based on query params instead of state to allow for deep linking
  useEffect(() => {
    // get location from path and set view
    const path = location.pathname;
    console.log("path", path, "got view: ", deriveViewFromPath(path));
    const view = deriveViewFromPath(path);
    changeView(view);
  }, [location]);
  //   const initialState = composeInitialState(item.itemId, item.name);
  //   const [reducer, dispatch] = useReducer(TradingReducer, initialState);
  const View = deriveViewFromSelectedTab(state.view);
  return (
    <div className="flex flex-col w-full">
      <div className="flex">
        <div className="flex flex-col w-full">
          <div className="flex Quantum-Container flex-row justify-between items-center">
            <h1 className="text-2xl font-bold text-white">Description</h1>
            <button
              className="text-white text-sm font-bold"
              onClick={toggleDescription}
            >
              {shouldShowDescription ? "-" : "+"}
            </button>
          </div>
          {shouldShowDescription && (
            <div className="Quantum-Container">
              <p className="text-white text-sm">
                {smartAssembly.description || "No description available"}
              </p>
            </div>
          )}
        </div>
      </div>
      <DexNav view={state.view} changeView={changeView} />
      <View
        characterId={characterId}
        tradeAssetBalance={tradeAssetBalance}
        tradeAssetDecimals={tradeAssetDecimals}
        tradeAssetTicker={tradeAssetTicker}
      />
    </div>
  );
};
export default Dex;
