import { useEffect, useReducer, useState } from "react";
import { useSmartObject } from "@eveworld/contexts";

import { FullItemMetadata, IMarketState } from "./types";
import { useMUD } from "@/MUDContext";
import { GatewayHTTPService } from "@/services";
import { OrderType, SavedOrder } from "@/types/orders";
import { useEffectOnce } from "@/utils/hooks/useEffectOnce";
import Loading from "./Loading";
import FixedLengthTable from "@/components/FixedLengthTable";
import { baseState, singleItemMarketReducer } from "./singleItemMarket.reducer";
import SingleMarkeItemBalances from "./SingleItemMarketBalances";
import TradeWindow from "./TradeWindow";
import { useLocation, useNavigate } from "react-router-dom";
import { BalanceModule } from "@/components/EntityComponents";
import { sortBigIntAscending, sortBigIntDescending } from "../../utils";

const SingleItemMarket = ({
  tradeAssetTicker,
  tradeAssetBalance,
  tradeAssetDecimals,
  state,
}: {
  tradeAssetTicker: string;
  tradeAssetBalance: bigint;
  tradeAssetDecimals: number;
  state: IMarketState;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [singleMarketState, dispatch] = useReducer(
    singleItemMarketReducer,
    baseState
  );
  const { smartCharacter, smartAssembly } = useSmartObject();
  const {
    systemCalls: {
      getAllItemOrders,
      getItemsTypeIds,
      getMarketPrice,
      getItemBalance,
    },
  } = useMUD();
  // get itemId from query params
  const itemId = new URLSearchParams(location.search).get("itemId");

  // get item orders
  const lookupMarketPrice = (fallback: boolean = false) => {
    if (!itemId || !smartAssembly) {
      console.error("TradeWindow: itemId or smartAssembly is null", {
        itemId: itemId,
        smartAssembly,
      });
      return;
    }
    // fetch price by order type
    dispatch({ type: "START_FETCHING_PRICE" });
    getMarketPrice(
      BigInt(smartAssembly.id),
      BigInt(itemId),
      fallback ? OrderType.LIMIT_SELL : OrderType.MARKET_SELL // @todo: change to order type
    )
      .then((d) => {
        // dfallback if price is 0 unless we're already on the fallback
        if (d === 0n && fallback !== true) {
          lookupMarketPrice(true);
          return;
        } else {
          console.warn("Fetched price", d);
          dispatch({
            type: "FETCHED_PRICE_SUCCESS",
            payload: {
              value: d,
              error: null,
            },
          });
        }
      })
      .catch((e: any) => {
        console.error("Error fetching price", e);
        dispatch({
          type: "FETCHED_PRICE_ERROR",
          payload: {
            value: null,
            error: "Unable to fetch price for item.",
          },
        });
      });
  };
  useEffectOnce(() => {
    lookupMarketPrice();
    const interval = setInterval(() => {
      lookupMarketPrice();
    }, 1000 * 4);
    return () => clearInterval(interval);
  });

  const fetchAndSetItemBalance = () => {
    if (!itemId || !smartAssembly?.id) {
      console.error("HEREEE", itemId, smartAssembly?.id);
      return;
    }
    const userAddress = smartCharacter.address;
    getItemBalance(
      userAddress,
      BigInt(smartAssembly?.id as string),
      BigInt(itemId)
    )
      .then((d) => {
        console.warn("User balance", d);
        dispatch({ type: "SET_USER_ITEM_BALANCE", payload: d });
      })
      .catch((err) => {
        console.error("Error fetching item balance for user", err);
      });
  };

  useEffectOnce(() => {
    fetchAndSetItemBalance();
    const interval = setInterval(() => {
      fetchAndSetItemBalance();
    }, 1000 * 5);
    return () => clearInterval(interval);
  });

  useEffectOnce(() => {
    if (!itemId || !smartAssembly?.id) {
      console.error("HEREEE", itemId, smartAssembly?.id);
      return;
    }
    getAllItemOrders(BigInt(smartAssembly?.id as string), BigInt(itemId)).then(
      (d) => {
        dispatch({ type: "SET_ITEM_ORDERS", payload: d });
      }
    );

    const interval = setInterval(() => {
      if (!itemId || !smartAssembly?.id) {
        console.error(
          " missing itemid or assembly id",
          itemId,
          smartAssembly?.id
        );
        return;
      }
      getAllItemOrders(BigInt(smartAssembly?.id as string), BigInt(itemId))
        .then((d) => {
          dispatch({ type: "SET_ITEM_ORDERS", payload: d });
        })
        .catch((err) => {
          console.error("err fetching orders", err);
        });
    }, 1000 * 5);
    return () => clearInterval(interval);
  });
  // get item metadata from gateway
  const fetchItemTypesFromGateway = async (
    items: { itemId: bigint; typeId: bigint }[]
  ): Promise<FullItemMetadata[]> => {
    // get types from gateway
    console.log("fetching item types from gateway", items);
    const out = await Promise.all(
      items.map(async (item) =>
        GatewayHTTPService.getInstance()
          .getTypeMetadata(item.typeId)
          .then((res: any) => {
            const data = res.data;
            if (!data || !data.cid) {
              console.error("No data found", item.typeId);
              return undefined;
            }
            return { ...item, metadata: res.data };
          })
          .catch((err: any) => {
            console.error(err);
            return undefined;
          })
      )
    )
      .then((d) => {
        return d.filter(Boolean);
      })
      .catch((err) => {
        console.error(err);
        return [];
      });
    const filtered = out.filter((d: FullItemMetadata | undefined) => !!d);
    return filtered as FullItemMetadata[];
  };

  // get item metadata
  useEffectOnce(() => {
    if (itemId) {
      getItemsTypeIds([BigInt(itemId)])
        .then((d) => {
          const metadatas = d.map((typeId) => {
            return { typeId, itemId: BigInt(itemId) };
          });
          fetchItemTypesFromGateway(metadatas)
            .then((item) => {
              if (!item || item.length === 0) {
                console.error("No item found");
                return;
              }
              console.debug(item);
              const metadata = {
                metadata: item[0].metadata,
                typeId: item[0].typeId,
                itemId: BigInt(itemId),
              };

              dispatch({ type: "SET_ITEM_META", payload: metadata });
            })
            .catch((err) => {
              console.error("error getting item metadata", err);
            });
        })
        .catch((err) => {
          console.error("error getting item type id", err);
        });

      const interval = setInterval(() => {
        if (!itemId) return;
        getItemsTypeIds([BigInt(itemId)])
          .then((d) => {
            const metadatas = d.map((typeId) => {
              return { typeId, itemId: BigInt(itemId) };
            });
            fetchItemTypesFromGateway(metadatas)
              .then((item) => {
                if (!item || item.length === 0) {
                  console.error("No item found");
                  return;
                }
                const metadata = {
                  metadata: item[0].metadata,
                  typeId: item[0].typeId,
                  itemId: item[0].itemId,
                };

                dispatch({ type: "SET_ITEM_META", payload: metadata });
              })
              .catch((err) => {
                console.error("error getting item metadata", err);
              });
          })
          .catch((err) => {
            console.error("error getting item type id", err);
          });
      }, 1000 * 5);
      return () => clearInterval(interval);
    }
  });

  const buyOrders = singleMarketState.itemOrders
    .filter((order) => {
      return order.orderType === OrderType.LIMIT_BUY;
    })
    .sort((a, b) => sortBigIntDescending(a.priceWithFee, b.priceWithFee));
  const sellOrders =
    // Object.values(
    singleMarketState.itemOrders
      .filter((order) => {
        return order.orderType === OrderType.LIMIT_SELL;
      })
      .sort((a, b) => sortBigIntDescending(a.priceWithFee, b.priceWithFee));
  //     .reduce(
  //       (acc, order) => {
  //         // create a new obj summing the remaining quantities
  //         const key = `${order.smartObjectId}_${order.priceWithFee.toString()}`;
  //         if (acc[key]) {
  //           acc[key].quantityRemaining += order.quantityRemaining;
  //         } else {
  //           acc[key] = order;
  //         }
  //         return acc;
  //       },
  //       {} as Record<string, SavedOrder>
  //     )
  // );
  return (
    <div className="flex flex-col w-full ">
      <div className={`p-2 bg-crude text-brightquantum`}>
        <button
          onClick={() => {
            // get existing query params
            const queryParams = new URLSearchParams(location.search);
            // strip itemId from query params
            queryParams.delete("itemId");
            // navigate to same path with new queryparams
            navigate(
              `${location.pathname}${queryParams.size > 0 ? `?${queryParams.toString()}` : ``}`
            );
          }}
        >{`< To Full Market`}</button>
      </div>
      {!singleMarketState.isLoadingItemMeta &&
      !singleMarketState.isLoading &&
      !!singleMarketState.itemMeta?.metadata?.metadata?.name ? (
        <div className="flex flex-col w-full">
          <div className="flex flex-row w-full">
            {singleMarketState.itemMeta.metadata.metadata.image ? (
              <img
                className="bg-[#381B0C] p-2 align-center c"
                style={{
                  maxHeight: "72px",
                  height: "72px",
                  width: "72px",
                }}
                src={singleMarketState.itemMeta.metadata.metadata.image.replace(
                  "https://sandbox-garnet-ipfs-gateway.nursery.reitnorf.com/ipfs/",
                  "https://mainnet-game-ipfs-gateway.nursery.reitnorf.com/ipfs/"
                )}
                alt={singleMarketState.itemMeta?.metadata?.metadata?.name}
              />
            ) : null}
            <div className="flex flex-row w-full">
              <div className="flex flex-col justify-between w-full py-2">
                <div
                  className="text-lg font-bold pl-1"
                  style={{ alignSelf: "left" }}
                >
                  {singleMarketState.itemMeta?.metadata?.metadata?.name}
                </div>

                {singleMarketState.itemMeta && (
                  <SingleMarkeItemBalances
                    state={singleMarketState}
                    item={singleMarketState.itemMeta}
                    tradeAssetBalance={tradeAssetBalance}
                    tradeAssetDecimals={tradeAssetDecimals}
                    tradeAssetTicker={tradeAssetTicker}
                  />
                )}
              </div>
            </div>
            <div className="flex bg-brightquantum text-crude p-2">
              <BalanceModule
                tokenAddress={tradeAssetTicker}
                tokenSymbol={tradeAssetTicker}
                quantity={tradeAssetBalance}
                decimals={tradeAssetDecimals}
                strAppend={tradeAssetTicker.toUpperCase()}
              />
            </div>
          </div>

          <div className="p-2 text-xs">
            {singleMarketState.itemMeta?.metadata?.metadata?.description}
          </div>
        </div>
      ) : (
        <Loading />
      )}
      {singleMarketState.itemMeta && singleMarketState.itemMeta.itemId ? (
        <>
          <TradeWindow
            itemId={singleMarketState.itemMeta.itemId.toString()}
            singleMarketState={singleMarketState}
            tradeAssetTicker={tradeAssetTicker}
            tradeAssetBalance={tradeAssetBalance}
            tradeAssetDecimals={tradeAssetDecimals}
          />
        </>
      ) : (
        <Loading />
      )}

      <div className="">
        {!singleMarketState.isLoadingItemMeta &&
        !singleMarketState.isLoading ? (
          <>
            <FixedLengthTable
              title="Sell Orders"
              headers={{
                quantity: { title: "Quantity" },
                price: { title: "Price" },
                location: { title: "Location" },
                jumps: { title: "Jumps", style: { maxWidth: "80px" } },
              }}
              tradeAssetTicker={tradeAssetTicker}
              arrayOfRecords={sellOrders.map((order) => {
                return {
                  price: order.price,
                  quantity: parseInt(order.quantityRemaining.toString()),
                  location: smartAssembly?.solarSystem.solarSystemName
                    ? `${smartAssembly?.solarSystem.solarSystemName} - ${smartAssembly.id.slice(0, 5)} `
                    : "Unknown",
                  jumps: "0",
                };
              })}
            />
            <FixedLengthTable
              title="Buy Orders"
              headers={{
                quantity: { title: "Quantity" },
                price: { title: "Price" },
                location: { title: "Location" },
                jumps: { title: "Jumps", style: { maxWidth: "80px" } },
              }}
              tradeAssetTicker={tradeAssetTicker}
              arrayOfRecords={buyOrders.map((order) => {
                return {
                  price: order.price,
                  quantity: parseInt(order.quantityRemaining.toString()),
                  location: smartAssembly?.solarSystem.solarSystemName
                    ? `${smartAssembly?.solarSystem.solarSystemName} - ${smartAssembly.id.slice(0, 5)} `
                    : "Unknown",
                  jumps: "0",
                };
              })}
            />
          </>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default SingleItemMarket;
