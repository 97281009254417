import { useEffect, useState } from "react";
import { useSmartObject } from "@eveworld/contexts";
import memoize from "memoizee";

import { useMUD } from "@/MUDContext";
import { IMarketState } from "./types";
import FullMarket from "./FullMarket";
import { useEffectOnce } from "@/utils/hooks/useEffectOnce";
import SingleItemMarket from "./SingleItemMarket";
import { useLocation } from "react-router-dom";

const baseState: IMarketState = {
  isLoading: true,
  itemOrders: null,
  itemsAvailable: [],
};

const Market = ({
  characterId,
  tradeAssetBalance,
  tradeAssetDecimals,
  tradeAssetTicker,
  showTradeAssetBalance = false,
}: {
  characterId: string;
  tradeAssetBalance: bigint;
  tradeAssetDecimals: number;
  tradeAssetTicker: string;
  showTradeAssetBalance?: boolean;
}) => {
  const { smartAssembly } = useSmartObject();
  const location = useLocation();
  const itemId = new URLSearchParams(location.search).get("itemId");
  const {
    systemCalls: { getItemsOnOrderAtSSU },
  } = useMUD();
  const [state, setState] = useState<IMarketState>(baseState);

  useEffect(() => {
    if (!itemId) return;
    if (!smartAssembly) {
      console.error("No smart assembly found");
    }
    // get the orders for the selected itemId
    // const orders =
  }, [itemId]);
  const memoizedItemsFetch = memoize(
    async () => {
      if (!smartAssembly || !smartAssembly.id) {
        console.error("No smart assembly found");
        return;
      }
      const itemsAvailable = await getItemsOnOrderAtSSU(
        BigInt(smartAssembly.id)
      );
      setState(
        (prevState: IMarketState) =>
          ({
            ...prevState,
            isLoading: false,
            itemsAvailable,
          }) as IMarketState
      );
    },
    { maxAge: 5000, promise: true }
  );
  useEffectOnce(() => {
    console.log("Items Available check");

    memoizedItemsFetch().finally(() => {
      console.log("Items Available check done");
    });
    const interval = setInterval(() => {
      memoizedItemsFetch();
    }, 5000);
    return () => clearInterval(interval);
  });
  return (
    <div className="flex">
      {itemId ? (
        <SingleItemMarket
          state={state}
          tradeAssetTicker={tradeAssetTicker}
          tradeAssetBalance={tradeAssetBalance}
          tradeAssetDecimals={tradeAssetDecimals}
        />
      ) : (
        <FullMarket state={state} />
      )}
    </div>
  );
};
export default Market;
